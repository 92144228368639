<template>
  <div>
    <div class="navi-link" @click.stop="dialog = true">
      <span class="navi-icon">
        <i class="flaticon-edit-1 text-warning"></i>
      </span>
      <span class="navi-text">{{ $t('ACTION.UPDATE') }} </span>
    </div>
    <v-dialog v-model="dialog" max-width="350">
      <v-card>
        <v-card-title class="headline">Hodim o'zgartirish</v-card-title>
        <v-card-text>
          <v-text-field
            label="Ism"
            v-model="getPersonal.first_name"
            dense
            outlined
          ></v-text-field>
          <v-text-field
            label="Familiya"
            v-model="getPersonal.last_name"
            dense
            outlined
          ></v-text-field>
          <v-text-field
            label="Pasport raqami"
            v-model="getPersonal.passport_number"
            dense
            outlined
          ></v-text-field>
          <v-text-field
            label="Telefon raqami"
            v-model="getPersonal.phone_number"
            dense
            outlined
          ></v-text-field>
          <v-text-field
            label="Pochta manzili"
            v-model="getPersonal.pochta_address"
            dense
            outlined
          ></v-text-field>
          <v-text-field
            label="Inn"
            v-model="getPersonal.inn"
            dense
            outlined
          ></v-text-field>
          <v-text-field
            label="Inps"
            v-model="getPersonal.inps"
            dense
            outlined
          ></v-text-field>
          <v-text-field
            label="Pinfl"
            v-model="getPersonal.pinfl"
            dense
            outlined
          ></v-text-field>
          <v-autocomplete
            v-model="oblastSelect"
            :items="Oblast"
            label="Oblast"
            item-text="name"
            item-value="id"
            return-object
            dense
            outlined
          ></v-autocomplete>
          <v-autocomplete
            v-model="regionSelect"
            :items="oblastRegions.oblast_region"
            label="Region"
            item-value="id"
            item-text="name"
            return-object
            dense
            outlined
          ></v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="error" small @click="dialog = false">
            Bekor qilish
          </v-btn>

          <v-btn color="primary" small @click="submit"> Saqlash </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      oblastSelect: '',
      regionSelect: '',
      adress_region: '',
      adress_oblast: '',
      oblastRegions: {}
    }
  },

  created() {
    this.$store.dispatch('getRegions')
    this.$store.dispatch('getAllPersonal')
    this.adress_oblast = this.staffData.adress_oblast
    this.adress_region = this.staffData.adress_region
  },
  watch: {
    getPersonal(val) {
      this.oblastSelect = val.adress_oblast
      this.regionSelect = val.adress_region
    },
    Oblast: function (val) {
      this.oblastRegions = val.find((x) => x.id == this.oblastSelect)
    },
    oblastSelect: function (val) {
      this.oblastRegions = val
      if (val.oblast_region == undefined) {
        this.oblastRegions = this.Oblast.find((x) => x.id == val)
      }
    }
  },
  computed: {
    getPersonal() {
      const data2 = {}
      const data3 = this.$store.getters.getAllPersonal.results.find(
        (obj) => obj.id == this.staffData.id
      )
      const returnedTarget = Object.assign(data2, data3)

      return returnedTarget
    },
    Oblast() {
      return this.$store.state.requests.allregions
    },
    allOblast() {
      return this.$store.getters.allOblast
    }
  },
  methods: {
    submit() {
      const id = {
        id: this.staffData.id
      }
      const data = {
        first_name: this.getPersonal.first_name,
        last_name: this.getPersonal.last_name,
        passport_number: this.getPersonal.passport_number,
        phone_number: this.getPersonal.phone_number,
        pochta_address: this.getPersonal.pochta_address,
        inn: this.getPersonal.inn,
        photo: this.null,
        inps: this.getPersonal.inps,
        pinfl: this.getPersonal.pinfl
      }
      if (this.regionSelect.id !== undefined) {
        data.adress_oblast = this.regionSelect.oblast
        data.adress_region = this.regionSelect.id
      }
      this.$store.dispatch('personalUpdate', { id, data })
      this.dialog = false
    }
  },
  props: {
    staffData: Object
  }
}
</script>

<style></style>
