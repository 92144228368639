<template>
  <div>
    <div class="row">
      <div class="col-12 mx-auto">
        <div class="card card-custom gutter-b example example-compact">
          <div
            class="
              card-header
              d-flex
              align-items-center
              justify-content-between
            "
          >
            <div class="card-title">
              <h3 class="card-label">{{ $t('MENU.STAFF.JS') }}</h3>
            </div>
            <!-- <div class="card-button">
              <create />
            </div> -->
            <div class="card-toolbar">
              <router-link
                to="/allpersonal/create"
                class="btn btn-primary font-weight-bolder"
              >
                <span class="svg-icon svg-icon-white"
                  ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2020-09-15-014444/theme/html/demo1/dist/../src/media/svg/icons/Communication/Group.svg--><svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      />
                    </g></svg
                  ><!--end::Svg Icon--></span
                >

                {{ $t('MENU.STAFF.JS') }} +</router-link
              >
            </div>
          </div>
          <div class="card-body">
            <!-- :loading="isLoading" -->
            <v-data-table
              :loading="isLoading"
              :headers="headers"
              no-data-text="Ma'lumotlar topilmadi"
              loading-text="Ma'lumotlar yuklanmoqda"
              :page.sync="page"
              single-select
              @click:row="rowClick"
              item-key="id"
              :items-per-page="itemsPerPage"
              :items="getAllPersonal"
              hide-default-footer
              class="row-pointer"
            >
              <template v-slot:[`item.index`]="{ item }">
                {{
                  getAllPersonal
                    .map(function (x) {
                      return x.id
                    })
                    .indexOf(item.id) + 1
                }}
              </template>
              <template v-slot:[`item.action`]="{ item }">
                <div style="text-align: right !important">
                  <action :index="item"></action>
                </div>
              </template>
            </v-data-table>
            <div class="text-left my-5">
              <v-pagination
                v-model="currentPage"
                :total-visible="10"
                @input="getPostData(currentPage)"
                :length="Math.ceil(getCount.count / 10)"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import action from './action'
// import create from './oldCreate'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  components: { action },
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      perPage: 10,
      currentPage: 1
    }
  },
  beforeCreate() {
    this.$store.dispatch('getRegions')
  },
  created() {
    // this.$store.dispatch("kontragentContractAktSverka");
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  computed: {
    headers() {
      return [
        {
          text: '#',
          value: 'index'
        },
        {
          text: this.$t('TABLE_HEADER.FIRSTNAME'),
          value: 'first_name'
        },
        {
          text: this.$t('TABLE_HEADER.SURNAME'),
          value: 'last_name'
        },
        {
          text: this.$t('TABLE_HEADER.LASTNAME'),
          value: 'father_name'
        },
        {
          text: this.$t('TABLE_HEADER.PASSPORT_NUMBER'),
          value: 'passport_number'
        },
        {
          text: this.$t('TABLE_HEADER.PHONE_NUMBER'),
          value: 'phone_number'
        },
        {
          text: this.$t('TABLE_HEADER.MAIL_ADDRESS'),
          value: 'pochta_address'
        },
        {
          text: this.$t('TABLE_HEADER.INN'),
          value: 'inn'
        },
        {
          text: this.$t('TABLE_HEADER.INSP'),
          value: 'inps'
        },
        {
          text: this.$t('TABLE_HEADER.PINFL'),
          value: 'pinfl'
        },
        {
          text: this.$t('TABLE_HEADER.PROVINCE'),
          value: 'adress_oblast_name'
        },
        {
          text: this.$t('TABLE_HEADER.REGION'),
          value: 'adress_region_name'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    },
    isLoading() {
      console.log('123', this.$store.state.requests.isLoading)
      return this.$store.state.requests.isLoading
    },
    getAllPersonal() {
      return this.$store.getters.getAllPersonal.results
    },
    getCount() {
      return this.$store.getters.getAllPersonal
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        row.select(false)
      }
    },
    getPostData(value) {
      if (parseInt(this.$router.currentRoute.query.page, 10) !== value) {
        this.$router.push({
          path: this.$router.currentRoute.path,

          query: { page: value }
        })
      }
      console.log(value)
      this.$store.dispatch('getAllPersonal', value)
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.STAFF.TITLE') },
      { title: this.$t('MENU.STAFF.JS') }
    ])
  }
}
</script>

<style></style>
